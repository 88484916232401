import React, { useState } from "react";
import "../FirstScreen/FirstScreen.css";
import InputWithMask from "../InputWithMask/InputWithMask";
import useInputValidation from "../../utils/validation";
import mainObject from "../../images/mainObject.svg";

function FirstScreen(props) {
  const [isVisibleInstruction, setIsVisibleInstruction] = useState(false);
  const [isBtnActive, setIsBtnActive] = useState(false);

  const licKey = useInputValidation("", {
    isEmpty: true,
    minLength: 27,
    isBtnClick: false,
  });

  const validationSpan = (
    <>
      {licKey.isDirty && licKey.validationMessage && (
        <span className="main__input-error">{licKey.validationMessage}</span>
      )}
    </>
  );

  const errorSpan = (
    <>
      <span className="main__input-error">{props.errorMessage}</span>
    </>
  );

  const versionSpan = props.errorMessage ? errorSpan : validationSpan;

  const changeVesibleInstruction = () => {
    setIsVisibleInstruction(!isVisibleInstruction);
    setIsBtnActive(!isBtnActive);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Принудительно вызываем валидацию перед отправкой формы
    licKey.handleOnClick();

    // Выполняем действия только если введённые данные валидны

    // Если данные валидны, выполняем действие
    props.onCheck(licKey.inputValue);
  };

  const resetButtonState = () => {
    setIsBtnActive(false);
  };

  return (
    <main className="main">
      <div className="container">
        <div className="main__inner">
          <div className="main__left">
            <h2 className="main__title">Добро пожаловать в Медиакласс ИИ</h2>
            <p className="main__subtitle">
              Ваш помощник в создании уникального{" "}
              <span style={{ whiteSpace: "nowrap" }}>медиа-контента:</span>{" "}
              просто загружайте, анализируйте и редактируйте!
            </p>

            <section
              id="main-screen"
              className={
                props.errorMessage
                  ? "mb-err main-screen main__box"
                  : "main-screen main__box"
              }
            >
              <div
                className={
                  isVisibleInstruction
                    ? "main__container"
                    : "main__container main__container-center"
                }
              >
                <form
                  className="main__form"
                  name="main__form"
                  onSubmit={handleSubmit}
                  noValidate
                >
                  <p className="main__box-title">
                    Для скачивания дистрибутива введите лицензионный ключ:
                  </p>
                  <label className="main__inpur-field">
                    <InputWithMask
                      value={licKey.inputValue}
                      onChange={licKey.handleInputChange}
                      onBlur={licKey.handleInputBlur}
                    />
                    {versionSpan}
                  </label>
                  <button
                    className="main__btn"
                    type="submit"
                    onClick={resetButtonState} // Сброс состояния кнопки после клика
                  >
                    Скачать
                  </button>
                </form>
              </div>
            </section>

            <div className="main__instructions">
              <a
                href="/docs/install_instructions.pdf"
                target="_blank"
                className="main__instruction"
              >
                <svg
                  className="main__instruction-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M14 11.5H8M10 15.5H8M16 7.5H8M20 7.3V17.7C20 19.3802 20 20.2202 19.673 20.862C19.3854 21.4265 18.9265 21.8854 18.362 22.173C17.7202 22.5 16.8802 22.5 15.2 22.5H8.8C7.11984 22.5 6.27976 22.5 5.63803 22.173C5.07354 21.8854 4.6146 21.4265 4.32698 20.862C4 20.2202 4 19.3802 4 17.7V7.3C4 5.61984 4 4.77976 4.32698 4.13803C4.6146 3.57354 5.07354 3.1146 5.63803 2.82698C6.27976 2.5 7.11984 2.5 8.8 2.5H15.2C16.8802 2.5 17.7202 2.5 18.362 2.82698C18.9265 3.1146 19.3854 3.57354 19.673 4.13803C20 4.77976 20 5.61984 20 7.3Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Инструкция по установке
              </a>

              <a
                href="/docs/user_guide.pdf"
                target="_blank"
                className="main__instruction"
              >
                <svg
                  className="main__instruction-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M14 11.5H8M10 15.5H8M16 7.5H8M20 7.3V17.7C20 19.3802 20 20.2202 19.673 20.862C19.3854 21.4265 18.9265 21.8854 18.362 22.173C17.7202 22.5 16.8802 22.5 15.2 22.5H8.8C7.11984 22.5 6.27976 22.5 5.63803 22.173C5.07354 21.8854 4.6146 21.4265 4.32698 20.862C4 20.2202 4 19.3802 4 17.7V7.3C4 5.61984 4 4.77976 4.32698 4.13803C4.6146 3.57354 5.07354 3.1146 5.63803 2.82698C6.27976 2.5 7.11984 2.5 8.8 2.5H15.2C16.8802 2.5 17.7202 2.5 18.362 2.82698C18.9265 3.1146 19.3854 3.57354 19.673 4.13803C20 4.77976 20 5.61984 20 7.3Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Руководство пользователя
              </a>
            </div>
          </div>
          <img src={mainObject} alt="" className="main__right" />
        </div>
      </div>
    </main>
  );
}

export default FirstScreen;
